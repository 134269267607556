

  <template>
  <div id="app">

    <router-view  name="header"></router-view>
    <router-view  name="body"></router-view>
    <router-view  v-scroll="handleScroll" id="temas" name="themes"></router-view>
    <router-view  name="case"></router-view>

    <router-view  id="quem" name="about"></router-view>
    <router-view  id="tray" name="tray"></router-view>
    <router-view  id="faq" name="faq"></router-view>

    <router-view  id="contato" name="form"></router-view>
    <router-view  name="footer"></router-view>


    <router-view name="partner2"></router-view>
    <router-view id="contatotray" name="linkTray"></router-view>
    <router-view id="contato" name="form2"></router-view>
    <router-view name="footer2"></router-view>



    <router-view name="headerDocV2"></router-view>
    <router-view name="bodyDocV2">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="form3"></router-view>
    <router-view name="footer3"></router-view>



    <router-view name="headerDocV3"></router-view>
    <router-view name="bodyDocV3">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv3"></router-view>
    <router-view name="footerv3"></router-view>

   


    <router-view name="headerDocV32"></router-view>
    <router-view name="bodyDocV32">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv32"></router-view>
    <router-view name="footerv32"></router-view>

   
   <router-view name="headerDocV34"></router-view>
    <router-view name="bodyDocV34">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv34"></router-view>
    <router-view name="footerv34"></router-view>

   
 <router-view name="headerDocV341"></router-view>
    <router-view name="bodyDocV341">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv341"></router-view>
    <router-view name="footerv341"></router-view>



 <router-view name="headerDocV342"></router-view>
    <router-view name="bodyDocV342">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv342"></router-view>
    <router-view name="footerv342"></router-view>



 <router-view name="headerDocV4"></router-view>
    <router-view name="bodyDocV4">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv4"></router-view>
    <router-view name="footerv4"></router-view>



 <router-view name="headerDocV41"></router-view>
    <router-view name="bodyDocV41">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv41"></router-view>
    <router-view name="footerv41"></router-view>


 <router-view name="headerDocV42"></router-view>
    <router-view name="bodyDocV42">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv42"></router-view>
    <router-view name="footerv42"></router-view>


    <router-view name="headerDocV43"></router-view>
    <router-view name="bodyDocV43">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv43"></router-view>
    <router-view name="footerv43"></router-view>



  <router-view name="headerDocV44"></router-view>
    <router-view name="bodyDocV44">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv44"></router-view>
    <router-view name="footerv44"></router-view>



  <router-view name="headerDocV5"></router-view>
    <router-view name="bodyDocV5">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv5"></router-view>
    <router-view name="footerv5"></router-view>


    <router-view name="headerDocV6"></router-view>
    <router-view name="bodyDocV6">
       <menuleft />       
    </router-view>
    <router-view id="contato" name="formv6"></router-view>
    <router-view name="footerv6"></router-view>





    <router-view name="bodyMeta"></router-view>

    <router-view name="bodyFacebook"></router-view>

    <router-view name="bodyWhastsapp"></router-view>

    <router-view name="bodyInsta"></router-view>




  </div>
</template>

<script>
export default {
  el: '#app',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


#app{
  
  font-family: 'Lato', sans-serif;
}

.container {
  width: 95%;
  margin: 0 auto;
}

h3.title-model {
  padding: 30px 0px;
  text-align: center;
  color: #020202cf;
  font-size: 45px;
  margin: 0px;
}

.box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 8px 20px;
  line-height: 1.3em;
  opacity: 0;
  color: #000;
  width: 200px;
  margin: 0 auto;
  margin-top: 30px;
  transform: translateZ(0);
  perspective: 1000px;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.1);
  transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
}


/*mobile*/
@media (max-width: 599px) {

  body{
        margin: 0px 10px !important;
  }

}

</style>
